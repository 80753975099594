/* Font Sizes in the Editor (apply the labels) */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before
{
    content: "Extra Small";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before
{
    content: "Small";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before
{
    content: "Medium";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before
{
    content: "Large";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before
{
    content: "Extra Large";
}

/* Font Families */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
{
    font-family: "Lato";
    content: "Lato";
}
.ql-picker.ql-font .ql-picker-label[data-value="PT-Serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="PT-Serif"]::before
{
    font-family: "PT Serif";
    content: "PT Serif";
}
.ql-picker.ql-font .ql-picker-label[data-value="Poppins"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Poppins"]::before
{
    font-family: "Poppins";
    content: "Poppins";
}
.ql-picker.ql-font .ql-picker-label[data-value="Oswald"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Oswald"]::before
{
    font-family: "Oswald";
    content: "Oswald";
}
/* Set content font-families */
.ql-font-Arial {
    font-family: "Arial";
}
.ql-font-Lato {
    font-family: "Lato";
}
.ql-font-Courier-New {
    font-family: "Courier New";
}
.ql-font-Times-New-Roman {
    font-family: "Times New Roman";
}
.ql-font-Poppins {
    font-family: "Poppins";
}
.ql-font-PT-Serif {
    font-family: "PT Serif";
}
.ql-font-Oswald {
    font-family: "Oswald";
}
/* Additional Tweaks to match the mockups */
/* Select Components */
.ql-font.ql-picker,
.ql-size.ql-picker {
    background-color: white;
    border: 1px solid #DFE1E3;
    border-radius: 4px;
}
/* Toolbar */
.ql-toolbar.ql-snow {
    background-color: #f2f4f7;
    border: unset;
    border-radius: 8px;
    padding: 12px;
}
.ql-toolbar .ql-formats {
    margin-right: unset !important;
}
/* Input Component */
.ql-container.ql-snow {
    border: 1px solid #ccc !important;
    border-radius: 8px;
    margin-top: 15px;
}
/* Font Select Component */
.ql-snow .ql-picker.ql-font {
    margin-right: 7px;
    margin-bottom: 5px;
    width: 138px;
}
/* Size Select Component */
.ql-snow .ql-picker.ql-size {
   width: 102px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border: unset;
}
.ql-toolbar.ql-snow .ql-picker .ql-picker-label .ql-stroke,
.ql-toolbar.ql-snow .ql-picker .ql-picker-label {
    color: rgb(34, 43, 69) !important;
    stroke: rgb(34, 43, 69) !important;
}
.ql-hidden {
    display: none;
}
.ql-container.ql-disabled * {
    cursor: inherit;
}
.ql-disabled .ql-editor {
    padding: 8px 12px !important;
}
